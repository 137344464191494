
import { defineComponent, onMounted, ref } from 'vue'
import {
  useMessage,
  NButton,
  NModal,
  NForm,
  NFormItem,
  NInput,
  NIcon,
  NSelect,
  NSwitch,
  NSpin,
} from 'naive-ui'
import { API } from '@/api/api'
import { AddOutline } from '@vicons/ionicons5'
import { useGrupos } from '@/store'

export default defineComponent({
  components: {
    NButton,
    NModal,
    NForm,
    NSelect,
    NFormItem,
    NInput,
    NIcon,
    NSwitch,
    NSpin,
    AddOutline,
  },
  props: {
    emitUpdate: {
      type: Function,
      required: true,
    },
  },
  emits: ['emitUpdate'],
  setup(props) {
    const message = useMessage()
    const showModal = ref(false)
    const model = ref({
      titulo: null,
      descricao: null,
      detalhamento: false,
      diretorio: null,
      id_grupo: null,
    })
    const grupos = useGrupos()
    const gruposOptions = ref([])
    const isLoading = ref<boolean>(true)

    async function createData() {
      try {
        isLoading.value = true
        const data = {
          ...model.value,
        }

        const response = await API().post(`/dashboard/subgrupos`, data)

        if (response.status === 200) {
          message.success('Subgrupo cadastrado com sucesso!')
          props.emitUpdate()
          showModal.value = false
        } else {
          message.error(
            'Erro ao cadastrar subgrupo, verifique se os dados estão corretos e tente novamente mais tarde'
          )
        }
      } catch (error) {
        console.error(error)
        message.error(
          'Erro interno - Tente novamente mais tarde e contate o suporte.'
        )
      } finally {
        isLoading.value = false
      }
    }

    async function openModal() {
      showModal.value = true
      isLoading.value = true
      await grupos.fetch(true)
      console.log(grupos.data)
      gruposOptions.value = grupos.data.map((grupo: any) => ({
        label: grupo.titulo,
        value: grupo.id,
      }))
      console.log(gruposOptions.value)
      isLoading.value = false
    }

    return {
      model,
      showModal,
      createData,
      openModal,
      gruposOptions,
      grupos,
      isLoading,
    }
  },
})
