
import { defineComponent, ref, onMounted, h } from "vue";
import { NDataTable, NTag, NSelect, NInput, NButton, NSpin } from "naive-ui";
import { useSubgrupos, useGrupos } from "@/store";
import { TableColumns } from "naive-ui/lib/data-table/src/interface";
import ModalEditSubgrupoVue from "./modals/ModalEditSubgrupo.vue";
import ModalAdicionarSubgrupo from "@/views/subgrupos/modals/ModalAdicionarSubgrupo.vue";
import ModalHistoricSubgrupo from "@/views/subgrupos/modals/ModalHistoricSubgrupo.vue";
import { SearchOutline } from "@vicons/ionicons5";

export default defineComponent({
  components: {
    NDataTable,
    NSelect,
    NInput,
    NButton,
    SearchOutline,
    ModalAdicionarSubgrupo,
    NSpin,
  },
  setup() {
    const subgrupos = useSubgrupos();
    const grupos = useGrupos();
    const gruposOptions = ref([]);
    const formRef = ref({
      id_grupo: null as any,
      search: null,
    });

    function getColumns() {
      return ref<TableColumns<any>>([
        {
          title: "Nome",
          key: "titulo",
        },
        {
          title: "Grupo",
          key: "grupo.titulo",
        },
        {
          title: "Status",
          key: "status",
          render(row) {
            return h(
              NTag,
              {
                style: {
                  marginRight: "6px",
                },
                type: row.status == 1 ? "success" : "error",
              },
              {
                default: () => {
                  if (row.status == 1) {
                    return "Ativo";
                  } else {
                    return "Inativo";
                  }
                },
              }
            );
          },
        },
        {
          title: "Ações",
          key: "acoes",
          align: "right",
          render: (row) => {
            return h("div", { class: "space-x-2 justify-end" }, [
              h(ModalEditSubgrupoVue, {
                data: row,
                emitUpdate: () => {
                  subgrupos.fetch();
                },
              }),
              h(ModalHistoricSubgrupo, {
                data: row,
              }),
            ]);
          },
        },
      ]);
    }

    async function search() {
      subgrupos.resetPagination();
      subgrupos.params = `&id_grupo=${formRef.value.id_grupo}&search=${formRef.value.search}`;
      await subgrupos.fetch();
    }

    onMounted(async () => {
      await subgrupos.fetch();
      await grupos.fetch(true);
      console.log(grupos.data);
      gruposOptions.value = grupos.data.map((grupo: any) => ({
        label: grupo.titulo,
        value: grupo.id,
      }));
    });

    return {
      subgrupos,
      formRef,
      columns: getColumns(),
      gruposOptions,
      search,
    };
  },
});
